.nav-button {
  position: fixed;
  background: #b0bec5;
  color: black;
  line-height: 20vh;
  text-align: center;
  font-size: 1.5em;
  cursor: pointer;
  top: 40vh;
  width: 2%;
  height: 20vh;
}

.nav-button:hover {
  background: #808e95;
}

.left-nav {
  left: 0;
}

.right-nav {
  left: 98%;
}

@media only screen and (max-width: 600px) {
  .nav-button {
    width: 5%;
  }

  .right-nav {
    left: 95%;
  }
}